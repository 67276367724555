<template>
  <b-card title="الاعتماد">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/add-accreditation')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/accreditations"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>     إضافة الاعتماد</span>
          </a>
        </template>
        <AddAccreditation />
      </b-tab>
      <b-tab @click="$router.push('/all-accreditations')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-accreditations"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span>  الاعتمادات</span>
          </a>
        </template>
        <router-view />
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import AllAccreditation from './AllAccreditation.vue'
import AddAccreditation from './AddAccreditation.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    AllAccreditation,
    AddAccreditation,
  },
  data() {
    return {

    }
  },
}
</script>
